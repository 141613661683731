import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
var ɵ0 = {
    component: 'home'
}, ɵ1 = {
    component: 'invoiceIncome'
}, ɵ2 = {
    component: 'invoicePayment'
}, ɵ3 = {
    component: 'employeeManagement'
}, ɵ4 = {
    component: 'organizationManagement'
}, ɵ5 = {
    component: 'projectManagement'
}, ɵ6 = {
    component: 'userProfile'
}, ɵ7 = {
    component: 'bankAccountManagement'
}, ɵ8 = {
    component: 'travelRequestManagement'
}, ɵ9 = {
    component: 'statePayment'
}, ɵ10 = {
    component: 'inHousePayment'
}, ɵ11 = {
    component: 'backupRestore'
}, ɵ12 = {
    component: 'userAuthorizationManagement'
}, ɵ13 = {
    component: 'leaveExcuseManagement'
}, ɵ14 = {
    component: 'courseSchedule'
}, ɵ15 = {
    component: 'salaryPayment'
}, ɵ16 = {
    component: 'prizeManagement'
}, ɵ17 = {
    component: 'otherIncomes'
}, ɵ18 = {
    component: 'fonIncome'
}, ɵ19 = {
    component: 'timesheetManagement'
}, ɵ20 = {
    component: 'activityTrackingReport'
}, ɵ21 = {
    component: 'notificationPreferences'
}, ɵ22 = {
    component: 'environmentVariableManagement'
}, ɵ23 = {
    component: 'proposalManagement'
}, ɵ24 = {
    component: 'estimation'
};
var appRoutes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot',
        children: [
            {
                path: '',
                component: ForgotPasswordComponent
            },
            {
                path: ':id',
                component: ResetPasswordComponent
            }
        ]
    },
    {
        path: 'invoiceIncome',
        loadChildren: './invoice-income/invoice-income.module#InvoiceIncomeModule',
        canActivate: [AuthGuard],
        data: ɵ1
    },
    {
        path: 'invoicePayment',
        loadChildren: './invoice-payment/invoice-payment.module#InvoicePaymentModule',
        canActivate: [AuthGuard],
        data: ɵ2
    },
    {
        path: 'employeeManagement',
        loadChildren: './employee-management/employee-management.module#EmployeeManagementModule',
        canActivate: [AuthGuard],
        data: ɵ3
    },
    {
        path: 'organizationManagement',
        loadChildren: './organization-management/organization-management.module#OrganizationManagementModule',
        canActivate: [AuthGuard],
        data: ɵ4
    },
    {
        path: 'projectManagement',
        loadChildren: './project-management/project-management.module#ProjectManagementModule',
        canActivate: [AuthGuard],
        data: ɵ5
    },
    {
        path: 'userProfile',
        component: ProfilePageComponent,
        canActivate: [AuthGuard],
        data: ɵ6
    },
    {
        path: 'bankAccountManagement',
        loadChildren: './bank-account-management/bank-account-management.module#BankAccountManagementModule',
        canActivate: [AuthGuard],
        data: ɵ7
    },
    {
        path: 'travelRequestManagement',
        loadChildren: './travel-request/travel-request.module#TravelRequestModule',
        canActivate: [AuthGuard],
        data: ɵ8
    },
    {
        path: 'statePayment',
        loadChildren: './state-payment/state-payment.module#StatePaymentModule',
        canActivate: [AuthGuard],
        data: ɵ9
    },
    {
        path: 'inHousePayment',
        loadChildren: './in-house-payment/in-house-payment.module#InHousePaymentModule',
        canActivate: [AuthGuard],
        data: ɵ10
    },
    {
        path: 'backupRestore',
        loadChildren: './backup-restore/backup-restore.module#BackupRestoreModule',
        canActivate: [AuthGuard],
        data: ɵ11
    },
    {
        path: 'userAuthorizationManagement',
        loadChildren: './user-authorization-management/user-authorization-management.module#UserAuthorizationManagementModule',
        canActivate: [AuthGuard],
        data: ɵ12
    },
    {
        path: 'leaveExcuseManagement',
        loadChildren: './leave-excuse-management/leave-excuse-management.module#LeaveExcuseManagementModule',
        canActivate: [AuthGuard],
        data: ɵ13
    },
    {
        path: 'courseSchedule',
        loadChildren: './course-schedule/course-schedule.module#CourseScheduleModule',
        canActivate: [AuthGuard],
        data: ɵ14
    },
    {
        path: 'salaryPayment',
        loadChildren: './salary-payment/salary-payment.module#SalaryPaymentModule',
        canActivate: [AuthGuard],
        data: ɵ15
    },
    {
        path: 'prizeManagement',
        loadChildren: './prize-management/prize-management.module#PrizeManagementModule',
        canActivate: [AuthGuard],
        data: ɵ16
    },
    {
        path: 'otherIncomes',
        loadChildren: './other-incomes/other-incomes.module#OtherIncomesModule',
        canActivate: [AuthGuard],
        data: ɵ17
    },
    {
        path: 'fonIncome',
        loadChildren: './fon-income/fon-income.module#FonIncomeModule',
        canActivate: [AuthGuard],
        data: ɵ18
    },
    {
        path: 'timesheetManagement',
        loadChildren: './timesheet-management/timesheet-management.module#TimesheetManagementModule',
        canActivate: [AuthGuard],
        data: ɵ19
    },
    {
        path: 'activityTrackingReport',
        loadChildren: './activity-tracking-report/activity-tracking-report.module#ActivityTrackingReportModule',
        canActivate: [AuthGuard],
        data: ɵ20
    },
    {
        path: 'notificationPreferences',
        loadChildren: './notification-preferences/notification-preferences.module#NotificationPreferencesModule',
        canActivate: [AuthGuard],
        data: ɵ21
    },
    {
        path: 'environmentVariableManagement',
        loadChildren: './environment-variable-management/environment-variable-management.module#EnvironmentVariableManagementModule',
        canActivate: [AuthGuard],
        data: ɵ22
    },
    {
        path: 'proposalManagement',
        loadChildren: './proposal-management/proposal-management.module#ProposalManagementModule',
        canActivate: [AuthGuard],
        data: ɵ23
    },
    {
        path: 'estimation',
        loadChildren: './estimation/estimation.module#EstimationModule',
        canActivate: [AuthGuard],
        data: ɵ24
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24 };
