import {Injectable} from '@angular/core';
import {SidebarDirective} from '../directives/sidebar.directive';
import {UtilsService} from './utils.service';
import {Employee} from '../../../models/employee';
import {ComponentUtil} from '../../util/ComponentUtil';
@Injectable({
    providedIn: 'root'
})
export class MenuControllerService {

    sideBarNumber = -1;
    selectedTab = 0;
    profilePageView: 'overview' | 'infoView' | 'inputView' | 'bankInputView' = 'overview';

    private defaultAdminMenuItems = [
        {
            label: ComponentUtil.MAIN_PARTS['income'],
            items: [
                {
                    component: 'invoiceIncome'
                },
                {
                    component: 'prizeManagement'
                },
                {
                    component: 'fonIncome'
                },
                {
                    component: 'otherIncomes'
                }
            ]
        },
        {
            label: ComponentUtil.MAIN_PARTS['outcome'],
            items: [
                {
                    component: 'invoicePayment'
                },
                {
                    component: 'inHousePayment'
                },
                {
                    component: 'statePayment'
                }
            ]
        },
        {
            label: ComponentUtil.MAIN_PARTS['management'],
            items: [
                {
                    component: 'salaryPayment'
                },
                {
                    component: 'projectManagement'
                },
                {
                    component: 'travelRequestManagement'
                },
                {
                    component: 'estimation'
                },
                {
                    component: 'proposalManagement'
                },
                {
                    component: 'bankAccountManagement'
                },
                {
                    component: 'timesheetManagement'
                },
                {
                    component: 'activityTrackingReport'
                },
                {
                    component: 'leaveExcuseManagement'
                },
                {
                    component: 'courseSchedule'
                }
            ]
        },
        {
            label: ComponentUtil.MAIN_PARTS['system'],
            items: [
                {
                    component: 'employeeManagement'
                },
                {
                    component: 'organizationManagement'
                },
                {
                    component: 'backupRestore'
                },
                {
                    component: 'userAuthorizationManagement'
                },
                {
                    component: 'notificationPreferences'
                },
                {
                    component: 'environmentVariableManagement'
                }
            ]
        }
    ];

    private profileMenuItems = [
        {
            label: 'Profil'
        },
        {
            label: 'Banka Hesapları'
        },
        {
            label: 'İzin Bilgisi'
        },
        {
            label: 'Ders Programı'
        },
        {
            label: 'Dosyalar',
            items: [
                {
                    label: ComponentUtil.PROFILE_PAGE_FILES['cv']
                },
                {
                    label: ComponentUtil.PROFILE_PAGE_FILES['sgk']
                },
                {
                    label: ComponentUtil.PROFILE_PAGE_FILES['contracts']
                }
            ]
        }
    ];

    sidebarItems = [];
    sidebar: SidebarDirective = null;
    isProfilePage = false;
    currentUserType = '';

    constructor() {
    }

    setMenuByAuthorization() {
        this.sidebarItems = UtilsService.deepCopy(this.defaultAdminMenuItems);
        this.sidebarItems.forEach(part => {
            for (const item of part.items) {
                item.label = ComponentUtil.COMPONENT_NAMES[item.component];
                item.href = '/' + item.component;
            }
        });
        if (this.currentUserType !== Employee.UserType.ADMIN) {
            this.sidebarItems[3].label = '';
        }
    }

    setCurrentUserType(userType) {
        this.currentUserType = userType;
    }

    showWhichSideBarItem(i) {
        if (this.isProfilePage) {
            this.profilePageView = 'overview';
            if (this.sideBarNumber === i && i === 4) {
                this.sideBarNumber = -1;
            } else {
                this.sideBarNumber = i;
                if (i !== 4) {
                    this.selectedTab = i;
                }
            }
        } else {
            if (this.sideBarNumber === i) {
                this.sideBarNumber = -1;
            } else {
                this.sideBarNumber = i;
            }
        }
    }

    showWhichFileItem(subitem) {
        switch (subitem.label) {
            case 'CV':
                this.selectedTab = 4;
                break;
            case 'SGK İşe Giriş':
                this.selectedTab = 5;
                break;
            case 'Sözleşmeler':
                this.selectedTab = 6;
                break;
        }
    }

    setProfileMenu() {
        this.isProfilePage = true;
        if (this.sidebar) {
            this.sidebar.animate();
        }
        this.sidebarItems = this.profileMenuItems;
    }

    resetSidebarItems() {
        this.isProfilePage = false;
        this.sideBarNumber = -1;
        if (this.sidebar) {
            this.sidebar.animate();
        }
    }

}
