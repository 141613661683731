import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from './auth/auth.guard';
import {LoginComponent} from './login/login.component';
import {ProfilePageComponent} from './profile-page/profile-page.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

const appRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: {
            component: 'home'
        }
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot',
        children: [
            {
                path: '',
                component: ForgotPasswordComponent
            },
            {
                path: ':id',
                component: ResetPasswordComponent
            }
        ]
    },
    {
        path: 'invoiceIncome',
        loadChildren: './invoice-income/invoice-income.module#InvoiceIncomeModule',
        canActivate: [AuthGuard],
        data: {
            component: 'invoiceIncome'
        }
    },
    {
        path: 'invoicePayment',
        loadChildren: './invoice-payment/invoice-payment.module#InvoicePaymentModule',
        canActivate: [AuthGuard],
        data: {
            component: 'invoicePayment'
        }
    },
    {
        path: 'employeeManagement',
        loadChildren: './employee-management/employee-management.module#EmployeeManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'employeeManagement'
        }
    },
    {
        path: 'organizationManagement',
        loadChildren: './organization-management/organization-management.module#OrganizationManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'organizationManagement'
        }
    },
    {
        path: 'projectManagement',
        loadChildren: './project-management/project-management.module#ProjectManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'projectManagement'
        }
    },
    {
        path: 'userProfile',
        component: ProfilePageComponent,
        canActivate: [AuthGuard],
        data: {
            component: 'userProfile'
        }
    },
    {
        path: 'bankAccountManagement',
        loadChildren: './bank-account-management/bank-account-management.module#BankAccountManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'bankAccountManagement'
        }
    },
    {
        path: 'travelRequestManagement',
        loadChildren: './travel-request/travel-request.module#TravelRequestModule',
        canActivate: [AuthGuard],
        data: {
            component: 'travelRequestManagement'
        }
    },
    {
        path: 'statePayment',
        loadChildren: './state-payment/state-payment.module#StatePaymentModule',
        canActivate: [AuthGuard],
        data: {
            component: 'statePayment'
        }
    },
    {
        path: 'inHousePayment',
        loadChildren: './in-house-payment/in-house-payment.module#InHousePaymentModule',
        canActivate: [AuthGuard],
        data: {
            component: 'inHousePayment'
        }
    },
    {
        path: 'backupRestore',
        loadChildren: './backup-restore/backup-restore.module#BackupRestoreModule',
        canActivate: [AuthGuard],
        data: {
            component: 'backupRestore'
        }
    },
    {
        path: 'userAuthorizationManagement',
        loadChildren: './user-authorization-management/user-authorization-management.module#UserAuthorizationManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'userAuthorizationManagement'
        }
    },
    {
        path: 'leaveExcuseManagement',
        loadChildren: './leave-excuse-management/leave-excuse-management.module#LeaveExcuseManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'leaveExcuseManagement'
        }
    },
    {
        path: 'courseSchedule',
        loadChildren: './course-schedule/course-schedule.module#CourseScheduleModule',
        canActivate: [AuthGuard],
        data: {
            component: 'courseSchedule'
        }
    },
    {
        path: 'salaryPayment',
        loadChildren: './salary-payment/salary-payment.module#SalaryPaymentModule',
        canActivate: [AuthGuard],
        data: {
            component: 'salaryPayment'
        }
    },
    {
        path: 'prizeManagement',
        loadChildren: './prize-management/prize-management.module#PrizeManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'prizeManagement'
        }
    },
    {
        path: 'otherIncomes',
        loadChildren: './other-incomes/other-incomes.module#OtherIncomesModule',
        canActivate: [AuthGuard],
        data: {
            component: 'otherIncomes'
        }
    },
    {
        path: 'fonIncome',
        loadChildren: './fon-income/fon-income.module#FonIncomeModule',
        canActivate: [AuthGuard],
        data: {
            component: 'fonIncome'
        }
    },
    {
        path: 'timesheetManagement',
        loadChildren: './timesheet-management/timesheet-management.module#TimesheetManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'timesheetManagement'
        }
    },
    {
        path: 'activityTrackingReport',
        loadChildren: './activity-tracking-report/activity-tracking-report.module#ActivityTrackingReportModule',
        canActivate: [AuthGuard],
        data: {
            component: 'activityTrackingReport'
        }
    },
    {
        path: 'notificationPreferences',
        loadChildren: './notification-preferences/notification-preferences.module#NotificationPreferencesModule',
        canActivate: [AuthGuard],
        data: {
            component: 'notificationPreferences'
        }
    },
    {
        path: 'environmentVariableManagement',
        loadChildren: './environment-variable-management/environment-variable-management.module#EnvironmentVariableManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'environmentVariableManagement'
        }
    },
    {
        path: 'proposalManagement',
        loadChildren: './proposal-management/proposal-management.module#ProposalManagementModule',
        canActivate: [AuthGuard],
        data: {
            component: 'proposalManagement'
        }
    },
    {
        path: 'estimation',
        loadChildren: './estimation/estimation.module#EstimationModule',
        canActivate: [AuthGuard],
        data: {
            component: 'estimation'
        }
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
