
export class ComponentUtil {

    static MAIN_PARTS = {
        income: 'Gelirler',
        outcome: 'Giderler',
        management: 'Yönetim',
        system: 'Sistem'
    };

    static COMPONENT_NAMES = {
        home: 'Ana Sayfa',
        userProfile: 'Profil',
        invoiceIncome: 'Kesilen Faturalar',
        prizeManagement: 'Ödüller',
        fonIncome: 'Fon',
        otherIncomes: 'Diğer Gelirler',
        invoicePayment: 'Fatura Ödemeleri',
        inHousePayment: 'Şirket İçi Harcamalar',
        statePayment: 'Vergi-SGK',
        salaryPayment: 'Maaşlar',
        bankAccountManagement: 'Banka Hesapları',
        projectManagement: 'Projeler',
        timesheetManagement: 'Timesheetler',
        travelRequestManagement: 'Seyahatler',
        leaveExcuseManagement: 'İzinler',
        courseSchedule: 'Ders Programları',
        activityTrackingReport: 'Teknokent',
        proposalManagement: 'Proposallar',
        backupRestore: 'Backup-Restore',
        userAuthorizationManagement: 'Kullanıcı Yetkileri',
        employeeManagement: 'Personeller',
        organizationManagement: 'Organizasyonlar',
        notificationPreferences: 'Bildirim Ayarları',
        environmentVariableManagement: 'Ortam Değişkenleri',
        estimation: 'Estimation',
    };

    static PROFILE_PAGE_FILES = {
        cv: 'CV',
        sgk: 'SGK İşe Giriş',
        contracts: 'Sözleşmeler'
    };

}
